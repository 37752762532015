.CallScreen {
  .ant-modal-body {
    background: #52c41a;
  }
  .anticon {
    color: #fff !important;
  }
  .ant-result-title {
    line-height: 32px;
    margin-bottom: 12px;
    color: #fff;
  }
  .ant-result-subtitle {
    font-size: 18px;
    color: #ffffffcc;
    font-weight: 500;
  }
  .ant-btn {
    background: #fff;
    color: #52c41a;
    border-color: #fff;
    font-weight: 500;
  }
}
