#root {
  height: 100vh;
}

.App.LoginView {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 32px 48px;
  .Header {
  }
  .MainContent {
    .Software__Header {
      text-align: center;
      h1 {
        line-height: 24px;
      }
      h2 {
        font-size: 15px;
        font-weight: normal;
      }
      p {
        font-size: 11px;
        margin-top: 12px;
        margin-bottom: 0px;
      }
    }
  }
  .Footer {
  }
}

.ant-btn-primary.green {
  background-color: #0069b8;
  border-color: #0069b8;
}
.ant-btn-link {
  color: #187c7c;
}

//   .Login {
//     display: flex;
//     align-content: center;
//     align-items: center;
//     justify-content: center;
//     .Wrapper {
//       width: 1000px;
//       margin-top: 32px;
//     }
//     .Software__Header {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       img {
//         width: 80px;
//       }
//       h1,
//       h2 {
//         margin: 0;
//         padding: 0;
//       }
//       h1 {
//         margin-top: 16px;
//       }
//       h2 {
//         font-weight: lighter !important;
//         font-size: 14px;
//         line-height: 16px;
//       }
//     }
//   }

body {
  background: #fff;
}

.fade-enter.ColumnLogin {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active.ColumnLogin {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit.ColumnLogin {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active.ColumnLogin {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active.ColumnLogin,
.fade-exit-active.ColumnLogin {
  transition: opacity 500ms, transform 500ms;
}
