.Configuration {
  .isDesktop & {
    margin-top: 24px;
  }
  .Wrapper {
    padding: 0px 12px;
    .ant-avatar {
      margin-right: 12px !important;
    }
    .ProfileHeader {
      padding: 0px;
      .ant-page-header-heading-left {
        flex-direction: column-reverse;
        align-items: flex-start;
        span {
          margin-right: 0px;
        }
      }
    }
    .ProfileButtons {
      margin-top: 16px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 32px;
      margin-bottom: 48px;
      // flex-wrap: wrap;
    }
    .ProfileButton__BigButton {
      flex: 1;
      flex-basis: calc(30% - 32px);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding-top: 13px;
      padding-bottom: 13px;
      text-align: center;
      max-width: 96px;
      max-height: 96px;
      &,
      * {
        cursor: pointer;
      }
      button {
        width: 64px !important;
        height: 64px !important;
        font-size: 32px;
        margin-bottom: 8px;
        padding-top: 16px;
      }
    }
  }
}
