.RegisterPatient {
  .horizontalButtons{
    display: flex;
    flex-direction: row;
    button {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 128px;
      span {
        &.anticon {
          svg {
            width: 48px;
            height: 48px;
          }
        }
        &:not(.anticon) {
          width: 100%;
          display: block;
          word-wrap:break-word;
          font-size: 15px;
          line-height: 14px;
          white-space: normal;
          padding-top: 8px;
        }
      }
    }
  }
  .isDesktop & {
    margin-top: 24px;
  }
  // background: #ccc;
  .Wrapper {
    .ant-steps {
      padding-top: 12px;
    }
    form.ant-form {
      .ant-form-item {
        margin-bottom: 6px;
      }
      .ant-form-item-label label {
        font-size: 17px;
        font-weight: bold;
      }
      #patient_birthday {
        padding-top: 12px;
      }
      .ant-form-item-control-input-content .ant-picker,
      #patient_birthday,
      #patient_sexId,
      .ant-select-selector {
        width: 100%;
        background: #f4f5f7;
        line-height: 48px !important;
        height: 48px;
        border-radius: 16px;
        border: 1px solid #ccc;
        padding-left: 12px;
        .ant-select-selection-item {
          line-height: 46px;
          padding-left: 16px;
        }
      }
      .ant-form-item-control {
        input,
        textarea {
          background: #f4f5f7;
          line-height: 32px;
          border-radius: 16px;
          padding-left: 16px;
          color: #000;
          &:disabled {
            opacity: 0.55;
          }
        }
      }
    }
    .ant-card {
      margin: 16px;
      .ant-btn {
        min-height: 56px;
      }
      .ant-divider {
        margin: 12px 0;
      }
      .ant-btn-primary {
        background: #0069b8;
        border-color: #0069b8;
      }
      &.TutorHead {
        .ant-card-head {
          background: #d31919;
        }
        .ant-btn-primary {
          background: #d31919;
          border-color: #d31919;
        }
      }
      &-head {
        background: #0069b8;
        color: #fff;
        border-radius: 16px 16px 0px 0px;
        font-size: 19px;
        .ant-card-head-title {
          padding: 12px 0px;
          padding-top: 14px;
        }
      }

      p {
        font-size: 18px;
        // margin-bottom: 4px;
      }
    }
    .ant-form button:last-child:not(.ant-switch) {
      margin-bottom: 64px;
    }
  }
}

.Button__Next,
.Button__WithoutRUT,
.Button__SaveAndNext {
  margin: 16px;
  margin-top: 0px;
  width: calc(100% - 32px);
  min-height: 48px;
  border-radius: 16px;
}
