.PaseVisita {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  .Logos {
    display: flex;
    flex-direction: row;
    height: 48px;
    justify-content: space-between;
  }
  .Patient {
    font-size: 11px;
    font-variant: small-caps;
  }
  .Center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 350px;
    .Right {
      text-align: right;
    }
    .Name {
      text-transform: uppercase;
      font-variant: small-caps;
      font-weight: bold;
      font-size: 28px;
      line-height: 30px;
    }
    .QR p {
      font-variant: small-caps;
      font-weight: bold;
      font-size: 18px;
      padding-left: 32px;
    }
    .Table {
      border: 1px solid #000;
      font-variant: small-caps;
      font-size: 18px;
      text-align: center;
      .Date {
        text-align: center;
        border-top: 1px solid #000;
        display: flex;
        justify-content: space-between;
        & > div {
          text-align: center;
        }
      }
    }
  }
}
@media print {
  .PaseVisita {
    display: flex;
  }
}
.PatientActivity {
  &__Body {
    font-size: 16px;
    padding: 16px;
    &__Form {
      &__Select {
        min-height: 40px;
        width: 100%;
        border: 1px solid #d9d9d9 !important;
        padding: 0px 6px;
        background: #fff;
        border-radius: 4px;
        font-size: 16px;
      }
      &__Button {
        button {
          min-height: 48px;
          height: 48px;
          background: #0069b8;
          border-radius: 8px;
        }
      }
    }
  }
  &__Header {
    padding: 12px 12px;
    background: #0069b8;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__Icon {
      width: 80px;
      height: 80px;
      min-width: 80px;
      min-height: 80px;
      background: #fff;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // box-shadow: #4793cc 0px 0px 0px 16px;
      // margin: 20px;
      // box-shadow: #1978BF 0px 0px 0px 16px;
      margin: 20px;
      border: 12px #4793cc solid;
      outline: 12px #1978bf solid;
      svg {
        width: 28px;
        height: 28px;
        color: #0069b8;
      }
      .svgInline {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      * {
        fill: #0069b8;
      }
    }
    &__Title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 12px;
      h1,
      h2 {
        color: #fff;
        font-weight: bold;
        margin: 0px;
        padding: 0px;
      }
      h1 {
        font-size: 18px;
      }
      h2 {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}
