.ant-btn-primary {
  &.green {
    background-color: #227bb6;
    border-color: #227bb6;
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: #185781 !important;
      border-color: #185781 !important;
    }
  }
}

.ant-spin-text {
  color: #0069b8;
}

.ant-spin-dot-item {
  background-color: #0069b8;
}

.ant-btn-default {
  &:hover,
  &-selected,
  &-active,
  &:focus {
    background: #3f860011;
    color: #3f8600;
    border-color: #3f8600;
  }
}
